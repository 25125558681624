import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/layout';
import CTA from '../../components/button';

const Page = props => (
  <Layout>
    <div>
      <Row className="heading" middle="xs" center="xs">
        <Col xs={10}>
          <h2 className="section-heading scroll">NOMA</h2>
          <h3>A Wide-set Display Font</h3>
          <Row start="xs">
            <p>
              Noma is a new wide-set display font with a minimal geometric style. I designed Noma because I wanted a
              tech-inspired font that has plenty of character while remaining legible. Noma is a great choice for
              high-impact headings and logotypes. I hope you enjoy, thanks for checking it out!
            </p>
            <p>Includes: 4 styles in OTF, TTF and Webfont formats.</p>
          </Row>
        </Col>
        <Col xs={10}>
          <Img fluid={props.data.cover.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.regular.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.cases.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.split.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.pangrams.childImageSharp.fluid} />
          <br />
          <Img fluid={props.data.ram.childImageSharp.fluid} />
        </Col>
      </Row>
      <Row center="xs">
        <a href="/shop/endure">
          <h2>Check out my other font, Endure.</h2>
        </a>
      </Row>
    </div>
  </Layout>
);

Page.displayName = 'Product Page';
export default Page;

export const pageQuery = graphql`
  query {
    cover: file(relativePath: { eq: "noma/Noma-Cover.png" }) {
      ...fluidImage
    }
    regular: file(relativePath: { eq: "noma/Noma-Regular-Glyphs.png" }) {
      ...fluidImage
    }
    cases: file(relativePath: { eq: "noma/Noma-Cases.png" }) {
      ...fluidImage
    }
    split: file(relativePath: { eq: "noma/Noma-Split-Glyphs.png" }) {
      ...fluidImage
    }
    pangrams: file(relativePath: { eq: "noma/Noma-Pangrams.png" }) {
      ...fluidImage
    }
    ram: file(relativePath: { eq: "noma/Noma-Demo-RAM.jpg" }) {
      ...fluidImage
    }
  }
`;
